import React from 'react'
import styles from './styles.module.css'
import MO_logo from '../../assets/img/MO_logo.png'
import Logo from '../../assets/img/logo.png'
import header_bg from '../../assets/img/header.png'

const Header = () => {
  return (
    <div className={styles.wrapper}>
        <div className={styles.wrapper_mo}>
            <img src={MO_logo}/>
            <span className={styles.minTitle}>Министерство обороны <br></br> Российской Федерации</span>
        </div>
            <span className={styles.title}>
                <img src={header_bg} className={styles.header_bg}/>
                <span>Международная выставка-форум «Россия»</span>
                </span>
        <img src={Logo} className={styles.logo_lms}/>
    </div>
  )
}

export default Header