import React, { useState } from 'react'
import styles from './styles.module.css'
import bg from '../../assets/test/background_test.png'
import Step_1 from './Step_1'
import Step_2 from './Step_2'
import Step_3 from './Step_3'
import Step_final from './Step_final'
import Step_test from './Step_test'

const Test = ({setProfPopup, setIsForm, list, info, setInfo}) => {

    const [step, setStep] = useState(1);
    const [step_, setStep_] = useState(0);
    const [ans, setAns] = useState([]);


    const handleBack = () =>  {
        setStep(prev => prev - 1);
    }
    const handleHome = () => {
        setProfPopup(false);
        setInfo({
            age: 18,
            sex: 'man',
            prof: '',        
        });
    }

    const handleCounter = (type) => {
        if (type == 'plus') {
            setInfo(prev => ({...prev, age: prev.age + 1}))
        } else if (type == 'minus') {
            setInfo(prev => ({...prev, age: prev.age - 1}))
        }
    }

  return (
    <div className={styles.wrapper}>
        <div className={styles.inner}>
            <img src={bg} className={styles.bg} />
            {/* {console.log(info, 'info')} */}
            { step === 0 ? <Step_1 setStep={setStep} handleBack={handleBack} handleHome={handleHome}/> : <></> }
            { step === 1 ? <Step_2 setStep={setStep} handleBack={handleBack} handleHome={handleHome} setInfo={setInfo} info={info} handleCounter={handleCounter}/> : <></> }
            { step === 2 ? <Step_3 setStep={setStep} handleBack={handleBack} handleHome={handleHome} setInfo={setInfo} info={info} /> : <></> }
            { step === 4 ? <Step_final 
                                setStep={setStep} 
                                handleBack={handleBack} 
                                handleHome={handleHome} 
                                setInfo={setInfo} 
                                info={info}
                                points={ans.filter(el=>el.bool).length}
                                setIsForm={setIsForm}
                                list={list}
                                /> : <></> }

            { step === 3 ? <Step_test 
                                setStep={setStep} 
                                handleBack={handleBack} 
                                handleHome={handleHome}
                                step_={step_}
                                setStep_={setStep_}
                                ans={ans}
                                setAns={setAns}
                                /> : <></> }
        </div>
    </div>
  )
}

export default Test