import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import guk from '../../assets/test/logo_guk.png'
import star from '../../assets/test/start.png'
import spec from '../../assets/spec.json'
import home from '../../assets/test/home.png'
import back from '../../assets/test/back.png'
import img1 from '../../assets/test/1.png'
import img2 from '../../assets/test/2.png'
import img3 from '../../assets/test/3.png'
import img4 from '../../assets/test/4.png'
import img5 from '../../assets/test/5.png'
import { getEdiInst } from '../../utils/getEduInst'


const Step_final = ({list, setStep, handleBack, handleHome, setInfo, info, handleCounter, points, setIsForm}) => {

    useEffect(() => {
        getEdiInst()
    },[])

    const getName = (url) => {
        const regex = /https:\/\/([^\/]+)\.mil\.ru/; 
        const match = url.match(regex); 
        const result = match[1];
        return result;
    }

    const handleSendEmail = () => {
        setIsForm({email:'', id: 88})
    }

    return (
    <div className={styles.content}>
        <div className={styles.header}>
            <img src={guk} className={styles.guk}/>
            <div className={styles.title}>Твой результат: <span className={styles.result}>{points} баллов из 12</span></div>
            <div className={styles.guk}></div>
        </div>
        
        <div style={{overflow: 'auto'}}>
        <div className={styles.text}>
            {points >= 10 
            ? 'По результат теста ты готов к обучению по выбранной профессии. Укажи свой e-mail, и мы пришлем всю необходимую информацию тебе на почту.По результат теста ты готов к обучению по выбранной профессии. Укажи свой e-mail, и мы пришлем всю необходимую информацию тебе на почту.' 
            : 'Тебе еще необходимо подготовиться для поступления, мы направим необходимую информацию на твою почту.'}
            
        </div>

        {
            Object.entries(spec[info.prof]).map(el => 
            <>
            <div className={styles.text}>Профессия: <span>{info.prof}</span></div>
            <div className={styles.text}>Специальность: <span>{el[0]}</span></div>
            <div className={styles.text_decr}>{[el[1].desc]?.desc}</div>
        
        <div className={styles.imgs}>
            <img src={img1} className={styles.img}/>
            <img src={img2} className={styles.img}/>
            <img src={img3} className={styles.img}/>
            <img src={img4} className={styles.img}/>
            <img src={img5} className={styles.img}/>
        </div>

        <div className={styles.text}>
            <div className={styles.text_vuz}>ВУЗы, осуществляющие подготовку:</div>
            
            {el[1].url?.map(el => 
                <div key={el} className={styles.vuzBlock}>
                    <div className={styles.emblema}><img src={`https://russia.nintegra.ru/logos/${getName(el)}.png`} /></div>
                    <span>
                        {list.find(_el => _el.logo == `/logos/${getName(el)}.png`)?.name}
                    </span>
                </div>)}
            
        </div>
        </>
        )}
        </div>
        <div className={styles.emailSend} onClick={handleSendEmail}>Отправить на e-mail</div>

        <div className={styles.footer}>
            <div className={styles.footer_start}>
                <div></div>
                {/* <div onClick={() => setStep(3)} className={styles.footer_start_next}>далее</div> */}
        </div>


        <div className={styles.footer_star}>
            <img src={home} className={styles.home} onClick={handleHome}/>
            <img src={star} className={styles.star}/>
            <img src={back} className={styles.back} onClick={handleBack}/>
        </div>
        </div>
    </div>
  )
}

export default Step_final