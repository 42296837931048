import React, { useState } from 'react'
import styles from './styles.module.css'
import guk from '../../assets/test/logo_guk.png'
import photo from '../../assets/test/photo.png'
import star from '../../assets/test/start.png'
import home from '../../assets/test/home.png'
import back from '../../assets/test/back.png'

const Step_test = ({setStep, handleBack, handleHome, step_, setStep_, ans, setAns}) => {

    const which = [
        {title: '123',
        type: 'which',
        questionList: [
          {
            question: 'День Героев Отечества посвящен возрождению традиции чествования героев. Как назывался этот праздник в дореволюционной России?',
            type: 'pick',
            answers: [{
              answer: 'День Георгиевских кавалеров',
              bool: true,
              id: 0
            },
            {
              answer: 'День Андрея Первозванного',
              bool: false,
              id: 0
            },
            {
              answer: 'День памяти русского воинства',
              bool: false,
              id: 0
            },
            {
              answer: 'День защитника Отечества',
              bool: false,
              id: 0
            }
            ] 
          },
          {
            question: 'В каком году учреждено звание Героя Советского Союза?',
            type: 'pick',
            answers: [{
              answer: 'В 1927',
              bool: false,
              id: 1,
            },
            {
              answer: 'В 1938',
              bool: false,
              id: 1,
            },
            {
              answer: 'В 1934',
              bool: true,
              id: 1,
            },
            {
              answer: 'В 1945',
              bool: false,
              id: 1,
            }
            ] 
          },
          {
            question: 'Какой норматив был у советских школьников по разборке-сборке АК?',
            type: 'pick',
            img: '/which_3.png',
            answers: [{
              answer: '15-25 секунд',
              bool: false,
              id: 2
            },
            {
              answer: '18-30 секунд',
              bool: true,
              id: 2
            },
            {
              answer: '18-28 секунд',
              bool: false,
              id: 2
            },
            {
              answer: '20-30 секунд',
              bool: false,
              id: 2
            }
            ] 
          },
          {
            question: 'Какое назначение было у «потешных войск» Петра I?',
            type: 'pick',
            answers: [{
              answer: 'Обучение юного царя военному делу',
              bool: true,
              id: 3
            },
            {
              answer: 'Участие в парадах',
              bool: false,
              id: 3
            },
            {
              answer: 'Выступление перед публикой как артисты',
              bool: false,
              id: 3
            },
            {
              answer: 'Первые российские военные оркестры',
              bool: false,
              id: 3
            }
            ] 
          },
          {
            question: 'Где расквартирован современный Преображенский полк и находится Главный Штаб ВВПОД «ЮНАРМИЯ»?',
            type: 'pick',
            answers: [{
              answer: 'В Военном городке «Лефортово»',
              bool: true,
              id: 4
            },
            {
              answer: 'В здании Московского Кремля',
              bool: false,
              id: 4
            },
            {
              answer: 'В Петропавловской крепости',
              bool: false,
              id: 4
            },
            {
              answer: 'В Севастополе',
              bool: false,
              id: 4
            }
            ] 
          },
          {
            question: 'Кто является Верховным Главнокомандующим Вооруженными Силами Российской Федерации?',
            type: 'pick',
            answers: [{
              answer: 'Президент',
              bool: true,
              id: 5
            },
            {
              answer: 'Министр обороны',
              bool: false,
              id: 5
            },
            {
              answer: 'Генерал армии',
              bool: false,
              id: 5
            },
            {
              answer: 'Председатель Правительства',
              bool: false,
              id: 5
            }
            ] 
          },
          {
            question: 'Какое воинское звание у Президента Российской Федерации Владимира Владимировича Путина?',
            type: 'pick',
            answers: [{
              answer: 'Генерал Армии',
              bool: false,
              id: 6
            },
            {
              answer: 'Генералиссимус',
              bool: false,
              id: 6
            },
            {
              answer: 'Маршал России',
              bool: false,
              id: 6
            },
            {
              answer: 'Полковник',
              bool: true,
              id: 6
            }
            ] 
          },
          {
            question: 'Сколько видов вооруженных сил есть в России?',
            type: 'pick',
            answers: [{
              answer: '3',
              bool: true,
              id: 7
            },
            {
              answer: '7',
              bool: false,
              id: 7
            },
            {
              answer: '5',
              bool: false,
              id: 7
            },
            {
              answer: '11',
              bool: false,
              id: 7
            }
            ] 
          },
          {
            question: 'Как называется орган местного самоуправления, занимающийся постановкой на учёт и призывом граждан на военную службу?',
            type: 'pick',
            answers: [{
              answer: 'Военный комиссариат',
              bool: true,
              id: 8
            },
            {
              answer: 'Генеральный Штаб',
              bool: false,
              id: 8
            },
            {
              answer: 'Муниципальное образование',
              bool: false,
              id: 8
            },
            {
              answer: 'Кадетский корпус',
              bool: false,
              id: 8
            }
            ] 
          },
          {
            question: 'Сколько городов носит звание «Город Воинской Славы»:',
            type: 'pick',
            answers: [{
              answer: '12',
              bool: false,
              id: 9
            },
            {
              answer: '22',
              bool: false,
              id: 9
            },
            {
              answer: '47',
              bool: true,
              id: 9
            },
            {
              answer: '50',
              bool: false,
              id: 9
            }
            ] 
          },
          {
            question: 'Герой Российской Федерации, офицер Управления «В» Центра специального назначения Федеральной службы безопасности Российской Федерации, полковник, принимавший участие в освобождении заложников во время теракта в Беслане. Назовите его имя:',
            type: 'pick',
            answers: [{
              answer: 'Дмитрий Разумовский',
              bool: false,
              id: 10
            },
            {
              answer: 'Роман Катасонов',
              bool: false,
              id: 10
            },
            {
              answer: 'Михаил Кузнецов',
              bool: true,
              id: 10
            },
            {
              answer: 'Вячеслав Бочаров',
              bool: true,
              id: 10
            }
            ] 
          },
          {
            question: 'Как называется документ, направляемый Военным комиссариатом, который уведомляет о необходимости прибыть в военкомат?',
            type: 'pick',
            answers: [{
              answer: 'Повестка',
              bool: true,
              id: 11
            },
            {
              answer: 'Контракт',
              bool: false,
              id: 11
            },
            {
              answer: 'Письмо',
              bool: true,
              id: 11
            },
            {
              answer: 'Договор',
              bool: false,
              id: 11
            }
            ] 
          },
        ]
      }
      ] 

    const handlePick = (item) => {
        if (ans.find(el => el?.id == item?.id)) {
        setAns(ans.filter(el => el?.id != item?.id));
        }
        setAns(prev => [...prev, item]);
    }

    const handleStep = () => {
        if (step_ == 11) {
            setStep(4);
        } else if (1) {
            setStep_(step_ + 1);
        }

    }
    

    return (
    <div className={styles.content}>
        <div className={styles.header}>
            <img src={guk} className={styles.guk}/>
            <div className={styles.title}>Тест</div>
            <div className={styles.guk}></div>
        </div>

            {
                <div className={styles.question} style={{height: '100%'}}>
                    <div className={styles.question_title}>{which[0]?.questionList[step_].question}</div>
                    <div className={styles.question_answers}>
                        {which[0]?.questionList[step_]?.answers.map((item_, index) =>
                            <div 
                                onClick={() => handlePick(item_)}
                                key={index} 
                                className={styles.question_answer} 
                                style={{color: item_?.answer == ans.find(el => el?.answer == item_?.answer)?.answer ? '#99dfff' : ''}}>
                                {`${['a', 'б', 'в', 'г'][index]}) `}{item_.answer}</div>
                        )}
                    </div>
                </div>
            }                

        <div className={styles.footer}>
            <div className={styles.footer_start}>
                {step_ > 0 ? <div onClick={() => setStep_(prev => prev - 1)} className={styles.footer_start_next}>назад</div> : <div></div>}
                <div onClick={handleStep} className={styles.footer_start_next}>далее</div>
        </div>
        </div>

        <div className={styles.footer_star}>
            <img src={home} className={styles.home} onClick={handleHome}/>
            <img src={star} className={styles.star}/>
            <img src={back} className={styles.back} onClick={handleBack}/>
        </div>
    </div>
  )
}

export default Step_test