import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Header from '../../components/header/Header'
import Speetch from '../../components/speetch/Speetch'
import Map from '../../components/map/Map'
import video from '../../assets/img/background.mp4'
import spec from '../../assets/spec.json'
import { getEdiInst } from '../../utils/getEduInst'
import { sendEmail } from '../../utils/sendEmail'
import { createNotification } from '../../components/Notifications/Notifications'
import { docs_1, docs_2 } from '../../assets/const'
import Keyboards from '../../components/Keyboard/Keyboard'
import SVO from '../../components/SVO/SVO'
import Test from '../../components/Test/Test'
import { IoCloseOutline } from 'react-icons/io5'
import Location from '../../components/Location/Location'

const Main = () => {

  const [isPopup, setIsPopup] = useState(null);
  const [isMap, setIsMap] = useState(true);
  const [isForm, setIsForm] = useState({email:'', id: null});
  const [speaking, setSpeaking] = useState(false);
  const [list, setList] = useState([]);
  const [svo, setSVO] = useState({});
  const [profPopup, setProfPopup] = useState(false);
  const [location, setLocation] = useState(localStorage?.getItem('location'));

  const [info, setInfo] = useState({
    age: 18,
    sex: 'man',
    prof: '',        
});
  const hanleCancel = () => {
    setIsForm({email:'', id: null});
  }
 
  const getName = () => {
    const type = list.find(el => el.id == isPopup)?.vuz == true;
    let newArr = [
        'Добрый день!',
        `\nВы запросили перечень документов для поступления в ${isForm.id==88 ? 'ВУЗ' : list.find(el => el.id == isPopup)?.name} через виртуального помощника системы мониторинга образовательной деятельности Управления военного образования Министерства обороны Российской Федерации.`,
        ...((type == true || isForm.id==88) ? docs_1 : docs_2),
        `\nС более подробной информацией можно ознакомиться на сайте ${isForm.id!=88 ? list.find(el => el.id == isPopup).site :
        `${Object.entries(spec[info?.prof]).map(el => `\n${el[1].url}\n`)}`
        }`,
      ]
    return newArr;
  }

  const handleSend = () => {    
    let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (re.test(isForm.email) == false) {
      return createNotification('error', 'Некорректная почта');
    }
    if (isForm.email.length === 0) {
      createNotification('error', 'Напишите свою почту');
    } else {
      isForm.email && sendEmail(isForm.email, getName());
      setIsForm({email:'', id: null});  
    }
    }

    useEffect(() => {
      list.length === 0 && getEdiInst(setList);
    }, [list])


  return (
    <div className={styles.wrapper}>
        <video autoPlay muted loop className={styles.myVideo}>
            <source src={video} type="video/mp4" />
        </video>
        <Header/>
        <Speetch isMap={isMap} isPopup={isPopup} setIsPopup={setIsPopup} setIsMap={setIsMap} speaking={speaking} setSpeaking={setSpeaking}/>
        {/* <Head isMap={isMap} setIsMap={setIsMap} speaking={speaking} setSpeaking={setSpeaking}/>   */}

        {isMap ? 
        <Map 
        isPopup={isPopup} 
        setIsPopup={setIsPopup} 
        isForm={isForm} 
        setIsForm={setIsForm} 
        list={list} 
        setList={setList} 
        svo={svo} 
        setSVO={setSVO} 
        profPopup={profPopup} 
        setProfPopup={setProfPopup}/> 
        : <></>}
        
        {isForm.id ?
            <div className={styles.form}>
            <div className={styles.formInner}>
              <div className={styles.close} onClick={hanleCancel}><IoCloseOutline/></div>
              <input 
                className={styles.input} 
                value={isForm.email} 
                placeholder='Email'
                disabled 
                onChange={e => setIsForm(prev => ({...prev, email: e.target.value}))} type='email'/>
              <div onClick={handleSend} className={styles.send}>Отправить</div>
            </div>
            <Keyboards 
              setInput={setIsForm}
              layout_name='shift'
              param='email'
              />
            </div> : <></>
        }

        {svo.secret ? <SVO svo={svo} setSVO={setSVO} location={location} /> : <></>}

        {profPopup ? <Test info={info} setInfo={setInfo} list={list} profPopup={profPopup} setProfPopup={setProfPopup} isForm={isForm} setIsForm={setIsForm} /> : <></>}
        {!location ? <Location location={location} setLocation={setLocation}/> : <></>}

    </div>
  )
}

export default Main