import React, { useState } from 'react'
import styles from './styles.module.css'
import Keyboards from '../Keyboard/Keyboard';
import {sendSVO} from '../../utils/sendSVO'
import { createNotification } from '../Notifications/Notifications';
import Ouseau from '../../assets/img/Ouseau.png'
import { IoCloseOutline } from "react-icons/io5";
import svoAudio from '../../assets/train_horn.mp3'
import Loader from '../Loader/Loader';
import MyDrawingApp from '../Doodle/Doodle'
const SVO = ({svo, setSVO, location}) => {
    
    const [paramName, setParamName] = useState('subject');
    const [popup, setPopup] = useState(false);
    const [isWarn, setIsWarn] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [brushColor, setBrushColor] = useState('#ff0606');


    const handleCancel = () => {
        setSVO({});
        setParamName('');
    } 

    const handleSend = () => {
        if (isLoad) return
        if (svo?.picture == null || svo?.picture == '') return createNotification('info', 'Добавьте рисунок!')
        if (svo?.body.length < 50) return createNotification('info', 'Российскому войну интересен каждый миг мирной жизни, напиши о твоих личных успехах в учебе, спорте или увлечениях!')
        if (svo?.subject?.trim()?.length > 0 && svo?.body?.trim()?.length > 0 && svo?.signaturies?.trim()?.length > 0) {
          setIsLoad(true);
          sendSVO(svo, setPopup, setParamName, setSVO, setIsWarn, setIsLoad, location);
        } else {createNotification('error', 'Заполните все поля!')}
    }

    const handleChange = (e) => {
        setSVO(prev => ({...prev, [e.target.name]: e.target.value}));
    }

    const handleClose = () => {
      setSVO({});
      setPopup(false)
    }

  return (
    <div>
        {
          popup ? 
          <div className={styles.popup}>
            <div className={styles.popup__content}>
              <div className={styles.close_popup} onClick={handleClose}><IoCloseOutline/></div>
            Ваше письмо отправлено российским солдатам, участвующим в специальной военной операции. 
            <br/>
            Спасибо Вам за ПОДДЕРЖКУ!!!  
            </div>  
          </div>
          : <></>
        }
        {
          isWarn ? 
          <div className={styles.popup}>
            <audio src={svoAudio} autoPlay={isWarn} loop="loop"/>
            <div className={styles.popup__content} style={{background: '#934242'}}>
              <div>Внимание! Ваше сообщение содержит нецензурные выражения. Письмо не будет отправлено!!!</div>
            </div>
          </div>
          : <></>
        }
        {
           <div className={styles.popupDraw}>
            <MyDrawingApp 
              setSVO={setSVO} 
              SVO={svo} 
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor} 
              brushColor={brushColor}
              setBrushColor={setBrushColor}
              />
            </div>
        }
        <div className={styles.form}>
            <div className={styles.formInner}>
              <img src={Ouseau} className={styles.Ouseau}/>
              <div className={styles.close} onClick={handleCancel}><IoCloseOutline/></div>

                <div className={styles.sold}>Письмо солдату...</div>
                  <input
                  value={svo.subject}
                  onChange={e => handleChange(e)}
                  placeholder='Тема'
                  name='subject'
                  className={styles.subject}
                  onFocus={e => setParamName('subject')}
                  />
                  <textarea
                  value={svo.body}
                  onChange={e => handleChange(e)}
                  placeholder='Текст'
                  name='body'
                  // blurOnEnter={true}
                  className={styles.body}
                  onFocus={e => setParamName('body')}
                  />

                  <div className={styles.signatureTitle}>Подпись:</div>
                  <textarea
                  value={svo.signaturies}
                  onChange={e => handleChange(e)}
                  placeholder={`Иван Петров\nученик 10А класса\nг. Ярославль`}
                  name='signaturies'
                  className={styles.signaturies}
                  onFocus={e => setParamName('signaturies')}
                  />

              <Keyboards setInput={setSVO} input={svo} param={paramName} layout_name='ru'/>
                
                <div className={styles.buttons}>
                  <div 
                    onClick={handleSend} 
                    className={styles.send}
                    disabled={isLoad}
                    >
                    {isLoad ? <div className={styles.loader}>
                      <Loader size={30} color='#fff'/></div> : ''}
                      Отправить
                  </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default SVO