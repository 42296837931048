export const speakUt = async (text, setIsLoad, setSpeaking, setError) => {
    const apiKey = "26e2f482-a7e3-46c7-8600-8ad7d8d0f368";
    const apiUrl = 'https://alesha.edumil.ru/api/yc/speech_synthesize';
    setIsLoad(true);
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Api-Key ${apiKey}`,
        },
        body: JSON.stringify({
          lang: 'ru-RU',
          // folderId: 'YOUR_FOLDER_ID', // ID вашей папки на Яндекс.Облаке (необходимо создать папку в Яндекс.Облаке)
          text: text,
          voice: 'filipp', // Имя голоса (можете изменить),
          speed: 1,
          emotion: 'neutral',
        }),
      })
  
        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        setSpeaking(true);
        await playAudio(new Audio(audioUrl));
        setSpeaking(false);

    } catch (error) {
        console.log(error);
        setError('server');    
    }
    
    setIsLoad(false);
  };

  function playAudio(audio){
    return new Promise(res=>{
      audio.play()
      audio.onended = res
    })
  }