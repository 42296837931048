import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataRu from '../../assets/cast.geo.json'
import { dataRu, univers_list } from '../../assets/const';
import regList from '../../assets/regList.json'
// import proj4 from 'proj4';
import scheme from '../../assets/img/scheme.PNG';
import proj4 from 'proj4';
import Info from '../info/Info';
import { IoCloseOutline } from 'react-icons/io5';

if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
  }

const Map = ({isForm, setIsForm, isPopup, setIsPopup, list, setList, svo, setSVO, profPopup, setProfPopup}) => {

    const [pointList, setPointList] = useState(null);
    const [position, setPosition] = useState({plotX: 0, plotY: 0, list: [], id: null, vuz: null, logo: null, imgs: null, regionCode: null});
    const [id, setId] = useState(null);
    const [isScheme, setIsScheme] = useState(false);


    useEffect(() => {
        const x =  list?.map(el => (
          {
            z: null, 
            lat: +el.coordinates.split(',')[0], 
            lon: +el.coordinates.split(',')[1], 
            id: el.id, 
            vuz: el.vuz,
            region: el.region,
            name_2: el.name, 
            logo: el.logo,
            imgs: el.imgs,
            regionCode: el.regionCode,
            name: list?.filter(_el => _el.region == el.region)?.length || 0
          } 
          ))
        setPointList(x);
      }, [list])

      const filtered = (list) => {
        const eduList = list;
        const uniqueEduList = eduList?.reduce((accumulator, currentValue) => {
          const isRegionUnique = !accumulator.find(item => item.region === currentValue.region);
          if (isRegionUnique) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);
        return uniqueEduList;
      }

    const optionsRu = {

        title: {
            text: ""
        },
        
        mapNavigation: {
          enabled: true,
          enableButtons: false
        },
    
        credits: {
          enabled: false
        },
        chart: {
          animation: false,
          backgroundColor: 'transparent',
          style :{
            boxShadow: '0 0 100px rgba(255, 255, 255, 1)',
          }
        },
        series: [
                {
                  name: "Карта",
                  mapData: mapDataRu,
                  // borderColor: 'rgb(0, 0, 0)',
                  // nullColor: '#EAD2D2',
                  showInLegend: false,
                  type: 'map',
                  data: dataRu,
                //   color: '#e7b3b3f0',
                },
                {
                  marker: {
                    lineWidth: 0,
                    radius: 8,
                    lineColor: '',
                    // symbol: `url(${geoRed})`,
                    fillColor: 'white',
                    lineColor: 'red',
                    lineWidth: 4,
                    radius: 20,
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                        return this.point.name;
                      },
                    verticalAlign: 'e',
                    shadow: false,
                    y: -15,
                    style:{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        textOutline: 'none',
                        color: 'red',
                        fontSize: '20px',
                        border: '3px solid red',
                      }
                  },
                  type: "mappoint",
                  name: "",
                  
                  showInLegend: false,
                  // enabled: false,
                  // useHTML:false,
                  // padding: 0,
                  // color: "brown",
    
                  data: filtered(pointList),
                  tooltip: {
                    // shared: true,
                    headerFormat: "Узнать",
                    pointFormat: '<b><br>',
                    enabled: false,
                  },
                  cursor: "pointer",
                  point: {
                    events: {
                      click: function() {
                        // console.log(this.region, this.id, this.plotX, this.plotY)
                        handleReg(this.region, this.id, this.plotX, this.plotY, this.vuz);
                        setId(this.id)
                        // const newList = [...activeSummary].map(el => el.id == this.summary_id ? {id: this.summary_id, value: this.summary_type} : el)
                        // setActiveSummary(newList);
                        // setIsShow(true);
                      }
                    },
                  }
                }
                ]
    }

    const handlePopupList = (list) => {
        setIsPopup(list.id);
        setPosition({id: null, plotX: 0, plotY: 0, list: [], regionCode: null});
      }

    const handleSVO = () => {
      setSVO({
        secret: 'AbCDeFg1i928jf92jf2',
        subject: '',
        body: '',
      })
    }

    const handleProf = () => {
      setProfPopup(true);
    }

    const handleReg = (region, id, plotX, plotY, vuz, logo, imgs, regionCode) => {    
        if (pointList.filter(el => el.region == region).length > 1) {
              setPosition(prev => ({
                ...prev, 
                id: region, 
                plotX: plotX, 
                plotY: plotY, 
                list: pointList.filter(el => el.region == region),
                vuz: vuz,
                logo: logo,
                imgs: imgs,
                region: regionCode
          }))
        } else {
          setIsPopup(id);
          setPosition({id: null, plotX: 0, plotY: 0, logo: null, vuz: null, list: [], imgs: null, regionCode: null});
        } 
      }

    return (
    <div className={styles.wrapper}>

      {
        isScheme ? <div className={styles.scheme}><i className={styles.close} onClick={() => setIsScheme(false)}><IoCloseOutline/></i><img src={scheme}/></div> : <></>
      }

        <div className={styles.counter}>
          <div className={styles.vuz}>Вуз - <span>{list.filter(el => el.vuz).length}</span></div>
          <div className={styles.dovuz}>Довуз - <span>{list.filter(el => !el.vuz).length}</span></div>
        </div>

        <div className={styles.counter_svo}>
          <div className={styles.svo} onClick={handleSVO}>Письмо на СВО</div>
          <div className={styles.profession} onClick={handleProf}>Выбери военную профессию</div>
          <div className={styles.profession} onClick={() => setIsScheme(true)}>Схема залов павильона</div>
        </div>

    {isPopup ? 
      <div className={styles.popup}>
        <div className={styles.popupInner}>
        <Info 
          data={list.find(el => el.id == isPopup)}
          id={isPopup} 
          setIsPopup={setIsPopup} 
          isForm={isForm} 
          setIsForm={setIsForm} 
          setId={setId} 
          />
        </div>
      </div>
    :
    <></>}

        {position.plotX > 0 && position.plotY > 0 &&
        <div 
        className={styles.popupListWrap}
        onClick={() => setPosition({id: null, plotX: 0, plotY: 0, vuz: null, list: [], imgs: null, regionCode: null})}
        >
          <div 
          className={styles.popupList}
          // style={{position:'absolute', zIndex: 111, top:`${position?.plotY}px`, left:`${position.plotX}px`}}
          >
            <div className={styles.popupListTitle}><bold>
            {regList.find(el => el.regionCode == position.id)?.regionName}
            &nbsp; 
            {regList.find(el => el.regionCode == position.id)?.federalUnit}</bold></div>
            <div className={styles.popupListWrapper}>
              <div className={styles.popupListContent}> 
              <div>
              <b>ВУЗы:</b>
              {position.list.filter(el => el.vuz === true).map(el => 
                <span className={styles.emblem_wrap} onClick={() => handlePopupList(el)}>
                  <img src={el.logo} className={styles.emblem} />
                  {el.name_2}</span>  
                )}
              </div>
              
              <div>
              <b>Довузы:</b>
              {position.list.filter(el => el.vuz === false).map(el => 
                <span className={styles.emblem_wrap} onClick={() => handlePopupList(el)}>
                  <img src={`https://russia.nintegra.ru${el.logo}`} className={styles.emblem} />
                  <div className={styles.emblem_txt}>{el.name_2}</div></span>  
                )}
              </div>
              </div>
            </div>
          </div>
        </div>
    }
        <div className={styles.map}>
            <HighchartsReact
            containerProps={{ style: { 
                backgroundColor: 'transparent', height: '100%', marginTop: '-20vh', zoom: 1 } }}
            options={optionsRu}
            highcharts = { Highcharts }
            constructorType={'mapChart'}/>
        </div>
    </div>
  )
}

export default Map