import { createNotification } from "../components/Notifications/Notifications";

export const sendSVO = async (data, setPopup, setParamName, setSVO, setIsWarn, setIsLoad, location) => {
    let fd = new FormData();
    data.signaturies = data.signaturies;
    fd.append("subject", data.subject);
    fd.append("body", `${data.body + `\n${data.signaturies}`+ "\nВыставка Россия, Москва, ВДНХ, Павильон «Армия детям», Суворовский класс"}`);
    fd.append("secret", data.secret);
    data.picture && fd.append("picture", data.picture);
    location && fd.append("location", location);
    try {
        const response = await fetch(`https://russia.nintegra.ru/api/sendmailsvo`, 
        {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            body: fd,
        });

        if (response.ok) {
          setParamName('');
          setPopup(true);
        } else {
          const err = await response.json();
          err?.warning && createNotification('error', err?.warning);
          setIsWarn(true);
          setTimeout(() => {	
            setIsWarn(false);	
          }, 10000)
        }
      } catch (ERR) {

      }    
      setIsLoad(false)
} 