import Main from "./pages/Main/Main";
import { NotificationContainer } from 'react-notifications'

import 'react-notifications/lib/notifications.css'
function App() {
  return (
    <div>
      <NotificationContainer />
      <Main/>
    </div>
  );
}

export default App;
