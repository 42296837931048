import React, { useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import styles from './styles.module.css';
import { TbEraser, TbEraserOff  } from "react-icons/tb";
import { IoTrash } from 'react-icons/io5';
import { createNotification } from '../Notifications/Notifications';

const MyDrawingApp = ({setSVO, SVO, backgroundColor, setBackgroundColor, brushColor, setBrushColor}) => {
  const canvasRef = useRef(null);

  const [brushRadius, setBrushRadius] = useState(5);
  const [brushType, setBrushType] = useState('round');
  const [isEraser, setIsEraser] = useState(false);
  const handleSave = () => {
    const canvasDataUrl = mergeCanvases(); // Используем функцию для объединения холстов
    setSVO(prev => ({...prev, picture: canvasDataUrl}));
    createNotification('success', 'Рисунок сохранен!');
  };

  const mergeCanvases = () => {
    const backgroundCanvas = document.createElement('canvas');
    const mainCanvas = canvasRef.current.canvasContainer.children[1];

    backgroundCanvas.width = mainCanvas.width;
    backgroundCanvas.height = mainCanvas.height;
    
    const backgroundCtx = backgroundCanvas.getContext('2d');
    backgroundCtx.fillStyle = backgroundColor;
    backgroundCtx.fillRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);

    // Рисуем задний холст (фон)
    backgroundCtx.drawImage(mainCanvas, 0, 0, backgroundCanvas.width, backgroundCanvas.height);

    return backgroundCanvas.toDataURL();
  };

  const handleClear = () => {
    canvasRef.current.clear();
    setSVO(prev => ({...prev, picture: ''}));
    setBackgroundColor('#ffffff');
    createNotification('success', 'Рисунок удален!');
  }
    
  return (
    <div className={styles.drawing_app}>
      
      <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.control_group} id="color">
          <label>Фон</label>
          <input
            className={styles.color}
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
          />
        </div>
        <div className={styles.control_group} >
          <label>Перо</label>
          <input
            className={styles.color}
            type="color"
            value={brushColor}
            onChange={(e) => setBrushColor(e.target.value)}
          />
        </div>
        
        <div className={styles.control_group}>
          <label>Толщина пера</label>
          <div className={styles.brush_radius_controls}>
            <div onClick={() => setBrushRadius(brushRadius - 1)} className={styles.buttonBrush}>-</div>
            <div className={styles.brush_radius_value}>{brushRadius}</div>
            <div onClick={() => setBrushRadius(brushRadius + 1)} className={styles.buttonBrush}>+</div>
          </div>
        </div>

        <div className={styles.control_group}>
          <label>Ластик</label>
          <i className={styles.eraser_icon}>{isEraser ? <TbEraser/> : <TbEraserOff/>}</i>
          <input
            className={styles.eraser}
            type="checkbox"
            checked={isEraser}
            onChange={() => setIsEraser(!isEraser)}
          />
        </div>

        <div className={styles.control_group}>
          <label>Очистить</label>
          <i className={styles.trash_icon} onClick={handleClear}><IoTrash/></i>
        </div>
      </div>
      </div>
      
      <CanvasDraw
        style={{ backgroundColor: backgroundColor }}
        ref={canvasRef}
        canvasWidth={500}
        canvasHeight={530}
        brushColor={isEraser ? backgroundColor : brushColor}
        brushRadius={parseInt(brushRadius, 10)}
        backgroundColor={backgroundColor}
        lazyRadius={0}
        brushType={brushType}
      />

      <button className={styles.save_button} onClick={handleSave}>Сохранить</button>
    </div>
  );
};

export default MyDrawingApp;