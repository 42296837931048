import { createNotification } from "../components/Notifications/Notifications";

export const sendEmail = async (email, body) => {
    const fd = new FormData();
    fd.append("to", email);
    fd.append("subject", "Документы на поступление");
    fd.append("body", body);
    fd.append("secret", 'AbCDeFg1i928jf92jf2');
    
    try {
        const response = await fetch(`https://russia.nintegra.ru/api/sendmail`, 
        {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            body: fd,
        });

        if (response.ok) {
            createNotification('success', 'Успешно!');
        } else {

            createNotification('error', 'Ошибка');
        }
      } catch (ERR) {
        // createNotification('error', 'Ошибка');
      }    
} 