import React from 'react'
import styles from './styles.module.css'
import guk from '../../assets/test/logo_guk.png'
import star from '../../assets/test/start.png'
import icon_man from '../../assets/test/icon_man.png'
import icon_girl from '../../assets/test/icon_girl.png'
import { RiTriangleFill } from "react-icons/ri";
import { MdDone } from "react-icons/md";
import home from '../../assets/test/home.png'
import back from '../../assets/test/back.png'

const Step_2 = ({setStep, handleBack, handleHome, setInfo, info, handleCounter}) => {
  return (
    <div className={styles.content}>
        <div className={styles.header}>
            <img src={guk} className={styles.guk}/>
            <div className={styles.title}>Выбери военную профессию</div>
            <div className={styles.guk}></div>
        </div>

        <div className={styles.wrapperAge}>
            <div className={styles.ageTitle}>Сколько тебе лет?</div>
            <div className={styles.age}>
                <span>{info.age}</span>
                <div className={styles.arrow}>
                    <div className={styles.arrowTop} onClick={() => handleCounter('plus')}><RiTriangleFill/></div>
                    <div className={styles.arrowBottom} onClick={() => handleCounter('minus')}><RiTriangleFill/></div>
                </div>
            </div>
        </div>

        <div className={styles.wrapperAge}>
            <div className={styles.ageTitle}>Какой ваш пол?</div>
            <div className={styles.sex}>
                <div 
                    onClick={() => setInfo(prev => ({...prev, sex: 'man'}))}
                    className={info.sex == 'man' ? styles.active_sex : styles.sex_block}>
                        {info.sex == 'man' ? <i className={styles.done}><MdDone/></i> : ''}
                    <span>мужской</span> <img src={icon_man}/></div>
                <div 
                    onClick={() => setInfo(prev => ({...prev, sex: 'girl'}))}
                    className={info.sex == 'girl' ? styles.active_sex : styles.sex_block}>
                        {info.sex == 'girl' ? <i className={styles.done}><MdDone/></i> : ''}
                    <span>женский</span> <img src={icon_girl}/></div>
            </div>
        </div>

        <div className={styles.footer}>
            <div className={styles.footer_start}>
                <div></div>
                <div onClick={() => setStep(2)} className={styles.footer_start_next}>далее</div>
        </div>

        <div className={styles.footer_star}>
            <img src={home} className={styles.home} onClick={handleHome}/>
            <img src={star} className={styles.star}/>
            <img src={back} className={styles.back}/>
        </div>
        </div>
    </div>
  )
}

export default Step_2