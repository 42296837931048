import logo_1 from './img/emblem_1.png'
import uni_1 from './img/univers/uni_1.png'
import uni_2 from './img/univers/uni_2.png'
import uni_3 from './img/univers/uni_3.png'
import uni_4 from './img/univers/uni_4.png'
import uni_5 from './img/univers/uni_5.png'
import uni_6 from './img/univers/uni_6.png'
import uni_7 from './img/univers/uni_7.png'
import uni_8 from './img/univers/uni_8.png'

import pos_1 from './test/position/Авиационный инженер.png'
import pos_2 from './test/position/Авиационный штурман.png'
import pos_3 from './test/position/Артиллерист.png'
import pos_4 from './test/position/Вертолетчик.png'
import pos_5 from './test/position/Военный инженер.png'
import pos_6 from './test/position/Военный медик.png'
import pos_7 from './test/position/Военный музыкант.png'
import pos_8 from './test/position/Военный переводчик.png'
import pos_9 from './test/position/Военный строитель.png'
import pos_10 from './test/position/Десантник.png'
import pos_11 from './test/position/Железнодорожник.png'
import pos_12 from './test/position/Журналист.png'
import pos_13 from './test/position/Инженер космической техники.png'
import pos_14 from './test/position/Кадровик.png'
import pos_15 from './test/position/Картограф.png'
import pos_16 from './test/position/Командир мотострелкового взвода.png'
import pos_17 from './test/position/Летчик.png'
import pos_18 from './test/position/Мальчик.png'
import pos_19 from './test/position/Метеоролог.png'
import pos_20 from './test/position/Метролог.png'
import pos_21 from './test/position/Морской пехотинец.png'
import pos_22 from './test/position/Моряк.png'
import pos_23 from './test/position/Оператор БПЛА.png'
import pos_24 from './test/position/Оператор_автоматизированных_систем_управления.png'
import pos_25 from './test/position/Офицер спецназа.png'
import pos_26 from './test/position/Программист.png'
import pos_27 from './test/position/Психолог.png'
import pos_28 from './test/position/Связист.png'
import pos_29 from './test/position/Специалист в области РХБЗ.png'
import pos_30 from './test/position/Специалист по МТО.png'
import pos_31 from './test/position/Специалист по РЭБ.png'
import pos_32 from './test/position/Специалист по защите информации.png'
import pos_33 from './test/position/Специалист_в_области_военно_политической_работы.png'
import pos_34 from './test/position/Специалист_по_физкультуре_и_спорту.png'
import pos_35 from './test/position/Финансист.png'
import pos_36 from './test/position/Химик.png'
import pos_37 from './test/position/Юрист.png'

export const dataRu = [ ["ru-hers", ''], ["ru-za", ''], ["ru-dn", ''], ["ru-lh", ''], ["ru-sc", ''], ["ru-2509", ''], ["ru-2485", ''], ["ru-ck", ''], ["ru-ar", ''], ["ru-nn", ''], ["ru-yn", ''], ["ru-ky", ''], ["ru-sk", ''], ["ru-kh", ''], ["ru-sl", ''], ["ru-ka", ''], ["ru-kt", ''], ["ru-2510", ''], ["ru-rz", ''], ["ru-sa", ''], ["ru-ul", ''], ["ru-om", ''], ["ru-ns", ''], ["ru-mm", ''], ["ru-ln", ''], ["ru-sp", ''], ["ru-ki", ''], ["ru-kc", ''], ["ru-in", ''], ["ru-kb", ''], ["ru-no", ''], ["ru-st", ''], ["ru-sm", ''], ["ru-ps", ''], ["ru-tv", ''], ["ru-vo", ''], ["ru-iv", ''], ["ru-ys", ''], ["ru-kg", ''], ["ru-br", ''], ["ru-ks", ''], ["ru-lp", ''], ["ru-ms", ''], ["ru-ol", ''], ["ru-nz", ''], ["ru-pz", ''], ["ru-vl", ''], ["ru-vr", ''], ["ru-ko", ''], ["ru-sv", ''], ["ru-bk", ''], ["ru-ud", ''], ["ru-mr", ''], ["ru-cv", ''], ["ru-cl", ''], ["ru-ob", ''], ["ru-sr", ''], ["ru-tt", ''], ["ru-to", ''], ["ru-ty", ''], ["ru-ga", ''], ["ru-kk", ''], ["ru-cn", ''], ["ru-kl", ''], ["ru-da", ''], ["ru-ro", ''], ["ru-bl", ''], ["ru-tu", ''], ["ru-ir", ''], ["ru-ct", ''], ["ru-yv", ''], ["ru-am", ''], ["ru-tb", ''], ["ru-tl", ''], ["ru-ng", ''], ["ru-vg", ''], ["ru-kv", ''], ["ru-me", ''], ["ru-ke", ''], ["ru-as", ''], ["ru-pr", ''], ["ru-mg", ''], ["ru-bu", ''], ["ru-kn", ''], ["ru-kd", ''], ["ru-ku", ''], ["ru-al", ''], ["ru-km", ''], ["ru-pe", ''], ["ru-ad", ''], ['ru-kr', '']];

export const docs_1 = [
    '\nВсе документы должны быть собраны и вложены в папку с файлами с обязательной описью вложенных документов на первой странице.',
    '\nЛичное дело включает следующие документы и сведения с описью вложения:',
    '\n1) Копии свидетельства о рождении и паспорта',
    '\n2) Автобиография — опишите основные события из своей жизни, которые характеризуют ваше происхождение, родственные связи, обучение, трудовую деятельность, семейное положение и другие сферы жизни',
    '\n3) Характеристика с места работы, учёбы или службы — в ней должны быть прописаны ваши личные и деловые качества, отношение к воинской службе',
    '\n4) Копия документа об образовании',
    '\n5) Три заверенных фото размером 4,5×6 см',
    '\n6) Справка об обучении или периоде обучения — для обучающихся в образовательных организациях высшего или среднего профессионального образования'
    ]

export const docs_2 = [
    '\nВсе документы должны быть собраны и вложены в папку с файлами с обязательной описью вложенных документов на первой странице.',
    '\nЛичное дело включает следующие документы и сведения с описью вложения:',
    '\n1) заявление родителей (законных представителей) кандидата на имя начальника училища;',
    '\n2) заявление кандидата на имя начальника училища;',
    '\n3) заверенная в установленном законодательством Российской Федерации порядке копия свидетельства о рождении (для лиц старше 14 лет дополнительно к копии свидетельства о рождении - заверенная копия 2, 3, 5 страниц паспорта гражданина Российской Федерации, удостоверяющего личность гражданина на территории Российской Федерации);',
    '\n4) автобиография кандидата;',
    '\n5) заверенные печатью установленного образца общеобразовательной организации копия личного дела кандидата, выписка из табеля его успеваемости за первые три четверти и текущие оценки за четвертую четверть учебного года, педагогическая и психологическая характеристики кандидата;',
    '\n6) четыре фотографии размером 3x4 см с местом для оттиска печати в правом нижнем углу;',
    '\n7) копия медицинского страхового полиса;',
    '\n8) копия Медицинской карты ф.026/у с результатами предварительного медицинского осмотра (осмотры врачей-специалистов, лабораторные, инструментальные и иные исследования, данные проведения дополнительных консультаций, исследований, назначенных в случае подозрения на наличие у несовершеннолетнего заболевания (состояния), диагноз которого не был установлен при проведении осмотров врачами-специалистами и основных исследований) с указанием группы состояния здоровья несовершеннолетнего и медицинской группы для занятий физической культурой;',
    '\n9) медицинское заключение о принадлежности несовершеннолетнего к медицинской группе для занятий физической культурой;',
    '\n10) оригинал выписки из истории развития ребенка (форма № 112/у), с указанием сведений, состоит ли ребенок на диспансерном наблюдении по поводу заболеваний, с указанием диагноза и даты постановки на учет (наблюдение) и сведений об отсутствии (наличии) аллергических состояний, в том числе препятствующих, проведению профилактических прививок и лечению антибиотиками, при наличии пищевой аллергии с указанием продуктов;',
    '\n11) сведения из психоневрологического и наркологического диспансеров о пребывании несовершеннолетнего на учете (наблюдении) по поводу психических расстройств, наркомании, алкоголизма, токсикомании, злоупотребления наркотическими средствами и другими токсическими веществами;',
    '\n12) копия сертификата о профилактических прививках (форма № 156/у-93) (на момент поступления дети должны быть привиты по возрасту);',
    '\n13) выписка из домовой книги с места проживания (регистрации);',
    '\n14) справка с места службы (работы) родителей (законных представителей);',
    '\n15) копия амбулаторной карты (история развития ребенка) ф.№ 025/у;',
    '\n16) документы, подтверждающие преимущественное право приема кандидата в училище;',
    '\n17) дополнительно к перечисленным документам (при их наличии) прилагаются документы, свидетельствующие о достижениях кандидата',
    // '\nС более подробной информацией можно ознакомиться на сайте училища https://ens.mil.ru/education/secondary.htm',
]

export const imgList = [uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8];

export const univers_list = [
    {
        id: 0,
        name: 'Военная академия материально-технического обеспечения ВА МТО',
        logo: logo_1,
        type: 1,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ]
    },
    {
        id: 1,
        name: 'Военно-медицинская академия ВМедА',
        logo: logo_1,
        type: 1,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    {
        id: 2,
        name: 'ВУНЦ СВ «Общевойсковая академия ВС РФ» ВУНЦ СВ «ОВА ВС РФ»',
        logo: logo_1,
        type: 1,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    {
        id: 3,
        name: 'Михайловская военная артиллерийская академия МВАА',
        logo: logo_1,
        type: 1,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    {
        id: 4,
        name: 'Московское суворовское военное училище МО РФ',
        logo: logo_1,
        type: 2,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    {
        id: 5,
        name: 'Московский кадетский корпус “Пансион воспитанниц МО РФ”',
        logo: logo_1,
        type: 2,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    {
        id: 6,
        name: 'Военная академия материально-технического обеспечения ВА МТО',
        logo: logo_1,
        type: 2,
        region_id: 22,
        place: "56.13095761982581,35.82370451918222",
        imgs: [
            uni_1, uni_2, uni_3, uni_4, uni_5, uni_6, uni_7, uni_8
        ] 
    },
    
]

export const layout_RU = {
    default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} й ц у к е н г ш щ з х ъ",
    "ф ы в а п р о л д ж э {enter}",
    "{shift} я ч с м и т ь б ю . {shift}",
    "@ {space}"
    ],
    shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Й Ц У К Е Н Г Ш Щ З Х Ъ",
    "Ф Ы В А П Р О Л Д Ж Э {enter}",
    "{shift} Я Ч С М И Т Ь Б Ю , {shift}",
    "@ {space}"
    ]
    }

export const layout_EN = {
    default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} q w e r t y u i o p [ ] \\",
        "{capslock} a s d f g h j k l ; ' {enter}",
        "{shiftleft} z x c v b n m , . / {shiftright}",
        ".com @ {space}"
    ]
}

export const layout_EN_shift = {
    shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
        "{tab} Q W E R T Y U I O P { } |",
        '{capslock} A S D F G H J K L : " {enter}',
        "{shiftleft} Z X C V B N M < > ? {shiftright}",
        ".com @ {space}"
    ]
}

export const position_img = [
    {name: 'Авиационный инженер', val: pos_1},
    {name: 'Авиационный штурман', val: pos_2},
    {name: 'Артиллерист', val: pos_3},
    {name: 'Вертолетчик', val: pos_4},
    {name: 'Военный инженер', val: pos_5},
    {name: 'Военный медик', val: pos_6},
    {name: 'Военный музыкант', val: pos_7},
    {name: 'Военный переводчик', val: pos_8},
    {name: 'Военный строитель', val: pos_9},
    {name: 'Десантник', val: pos_10},
    {name: 'Железнодорожник', val: pos_11},
    {name: 'Журналист', val: pos_12},
    {name: 'Инженер космической техники', val: pos_13},
    {name: 'Кадровик', val: pos_14},
    {name: 'Картограф', val: pos_15},
    {name: 'Командир мотострелкового (танкового) взвода', val: pos_16},
    {name: 'Летчик', val: pos_17},
    {name: 'Метеоролог', val: pos_19},
    {name: 'Метролог', val: pos_20},
    {name: 'Морской пехотинец', val: pos_21},
    {name: 'Моряк', val: pos_22},
    {name: 'Оператор БПЛА', val: pos_23},
    {name: 'Оператор автоматизированных систем управления', val: pos_24},
    {name: 'Офицер спецназа', val: pos_25},
    {name: 'Программист', val: pos_26},
    {name: 'Психолог', val: pos_27},
    {name: 'Связист', val: pos_28},
    {name: 'Специалист в области РХБ защиты', val: pos_29},
    {name: 'Специалист по материально-техническому обеспечению', val: pos_30},
    {name: 'Специалист по радиоэлектронной борьбе', val: pos_31},
    {name: 'Специалист по защите информации', val: pos_32},
    {name: 'Специалист в области военно-политической работы', val: pos_33},
    {name: 'Специалист по физкультуре и спорту', val: pos_34},
    {name: 'Финансист', val: pos_35},
    {name: 'Химик', val: pos_36},
    {name: 'Юрист', val: pos_37},
]