import React from 'react'
import styles from './styles.module.css'
import guk from '../../assets/test/logo_guk.png'
import photo from '../../assets/test/photo.png'
import star from '../../assets/test/start.png'
import home from '../../assets/test/home.png'
import back from '../../assets/test/back.png'

const Step_1 = ({setStep, handleBack, handleHome}) => {
  return (
    <div className={styles.content}>
        <div className={styles.header}>
            <img src={guk} className={styles.guk}/>
            <div className={styles.title}>Немного о себе</div>
            <div className={styles.guk}></div>
        </div>

        <div className={styles.wrapperPhoto}>
            <div className={styles.photoTitle}>Посмотрите в камеру и <br></br> нажмите внизу<img src={photo} className={styles.photo}/></div>
        </div>

        <div className={styles.photoBlock}>
            <div className={styles.photo_man}></div>
            <img src={photo} className={styles.photo_big}/>
        </div>

        <div className={styles.footer}>
            <div className={styles.footer_start}>
                <div>переснять</div>
                <div onClick={() => setStep(1)} className={styles.footer_start_next}>далее</div>
        </div>

        <div className={styles.footer_star}>
            <img src={home} className={styles.home} onClick={handleHome}/>
            <img src={star} className={styles.star}/>
            <img src={back} className={styles.back}/>
        </div>
        </div>
    </div>
  )
}

export default Step_1