import React, { useState } from 'react'
import styles from './styles.module.css'
import { createNotification } from '../Notifications/Notifications'

const Location = ({location, setLocation}) => {
    const [loc, setloc] = useState('')
    const handleSaveLocation = () => {
        if (loc && loc.length > 2) {
            setLocation(loc);
            localStorage.setItem('location', loc);
        } else {
          createNotification('error', 'Введите минимум 3 символа');
        }
    }

    return (
        <div  location={location} setLocation={setLocation} className={styles.location}>
          <div className={styles.location_content}>
            <div className={styles.location_title}>Введите имя компьютера</div>
            <input value={loc} onChange={e => setloc(e.target.value)} type='text' className={styles.location_input}/>
            <div onClick={handleSaveLocation} className={styles.location_send}>Сохранить</div>
          </div>
        </div>
    )
}

export default Location