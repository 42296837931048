import React from 'react'
import styles from './styles.module.css'
import guk from '../../assets/test/logo_guk.png'
import star from '../../assets/test/start.png'
import spec from '../../assets/spec.json'
import home from '../../assets/test/home.png'
import back from '../../assets/test/back.png'
import { position_img } from '../../assets/const'
import boy from '../../assets/test/position/Мальчик.png'

const Step_2 = ({setStep, handleBack, handleHome, setInfo, info, handleCounter}) => {
  
    const getList = () => {
        let specList = [];
        for (const key in spec) {
            if (Object.hasOwnProperty.call(spec, key)) {
                specList.push(key);
            }
        }
        return specList
    }
  
    return (
    <div className={styles.content}>
        <div className={styles.header}>
            <img src={guk} className={styles.guk}/>
            <div className={styles.title}>Выбери военную профессию</div>
            <div className={styles.guk}></div>
        </div>

            <div className={styles.spec}>
                {getList().map(el => 
                <>
                    <div className={styles.position_image}>
                    <img src={position_img?.find(el => el?.name == info?.prof.trim(''))?.val} className={styles.positionIcon}/>
                 
                    <img 
                        src={boy} 
                        className={styles.boy}
                        style={
                            {opacity: (position_img?.find(el => el?.name == info?.prof.trim(''))?.val.includes('спецназа')) ? '0' : '1'}
                        } 
                    />
                    </div>
                    
                    <div 
                        className={styles.specItem} 
                        style={{color: info.prof == el ? '#99dfff' : ''}}
                        onClick={() => setInfo(prev => ({...prev, prof: el}))}
                        >{el}
                    </div>
                </>
                )}
            </div>

        <div className={styles.footer}>
            <div className={styles.footer_start}>
                <div></div>
                <div onClick={() => info.prof && setStep(3)} className={styles.footer_start_next}>далее</div>
        </div>

        <div className={styles.footer_star}>
            <img src={home} className={styles.home} onClick={handleHome}/>
            <img src={star} className={styles.star}/>
            <img src={back} className={styles.back} onClick={handleBack}/>
        </div>
        </div>
    </div>
  )
}

export default Step_2