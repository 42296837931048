import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import {imgList} from '../../assets/const'
import button from '../../assets/img/button.png'
import { getEduInstById } from '../../utils/getEduInstById'

const Info = ({id, data = {}, isForm, setIsForm, setIsPopup, setId}) => {

const [item, setItem] = useState({});

  const handleBack = () => {
    setIsPopup(false);
    setId(null);
  }

  useEffect(() => {
    id && getEduInstById(id, setItem);
  }, [id])
  
  return (
    <div className={styles.wrapper}>
        <img src={`https://russia.nintegra.ru${data?.logo || ''}`} className={styles.emblem}/>
        <img src={button} className={styles.button} onClick={() => setIsForm({email: '', id: 15})}/>
        
        <div className={styles.back} onClick={handleBack}>назад</div>

        <div>
          <div className={styles.title}>{data?.name || '-'}</div>
          <div className={styles.row}><span>Адрес:</span>{data?.address || '-'}</div>
          <div className={styles.row}><span>Телефон:</span>{data?.phone || '-'}</div>
          {/* <div className={styles.row}><span>Факс:</span>{data?.faks || '-'}</div> */}
          {/* <div className={styles.row} style={{marginBottom: '30px'}}><span>Приемная комиссия</span>{data?.priem || '-'}</div> */}
          <div className={styles.row}><span>Email:</span>{data?.email || '-'}</div>
          <div className={styles.row}><span>Сайт:</span>{data?.site || '-'}</div>
        </div>
        {/* <div className={styles.docTitle}>Документы для поступления:</div> */}

        {/* <div className={styles.docWrapper}>
            {docs.map(el => 
                <div className={styles.dosItem}>{el}</div>    
            )}
        </div> */}

        <div className={styles.imgsWrapper}>
          {
            item?.images?.map(el => <img src={el} className={styles.imgsItem} />)
          }
        </div>

    </div>
  )
}

export default Info