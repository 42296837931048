import React, { useState, useEffect } from 'react';
import styles from './styles.module.css'
import {layout_EN, layout_RU} from '../../assets/const'
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";

const Keyboards = ({input = {}, setInput, param = '', layout_name = 'en'}) => {

    const [keyboardLayout, setKeyboardLayout] = useState('default');

    const onChangeInput = (e) => {
            setInput(prev => ({...prev, [param]: e}))
        };
        
    const onKeyPress = (button) => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
        if (button === '{backspace}') handleBackspace();
        
    };
        
    const handleShift = () => {
        let layoutName = keyboardLayout;
        setKeyboardLayout(layoutName === 'default' ? 'shift' : 'default');
    };

    const handleEnter = () => {

        setInput((prevInput) => ({...prevInput, [param]: `${input[param] + "\n"}`}));
    };

    const handleBackspace = () => {
        setInput((prevInput) => ({...prevInput, [param]: input[param].slice(0, -1)}));
    }
        
    useEffect(() => {
        setInput(prev => ({...prev, [param]: ''}))
        setKeyboardLayout('default');
        }, []);

    return (
    <div className={styles.keyboard}>
    <Keyboard
    syncInstanceInputs={true}
    mergeDisplay={true}
    disableButtonHold={true}
    layoutName={keyboardLayout}
    layout={layout_name == 'ru' ? layout_RU : layout_EN}
    onChange={onChangeInput}
    onKeyPress={onKeyPress}
    newLineOnEnter 
    inputName={param}
    value={input[param]}
    />
    </div>
  )
}

export default Keyboards