import React, { useEffect, useMemo, useState, useRef  } from 'react';
import styles from './styles.module.scss'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import {speakUt} from '../../utils/getSpeak'
import Loader from '../Loader/Loader';
import sorry from '../../assets/img/sorry.mp3'
import main_page from '../../assets/img/main_page.mp3'

const Speech = ({setIsMap, isMap, speaking, setSpeaking, isPopup, setIsPopup}) => {

  const { transcript, resetTranscript } = useSpeechRecognition();
  const [count, setCount] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState('');
  const [local, setLocal] = useState(false);

  const audioRefErr = useRef(null);
  const audioRefLocal = useRef(null);

  const checkWord = (transcript, word) => {
    if (transcript.toLocaleLowerCase().includes(word)) {
      return true;
    }
    return false;
  }

  const handleSpeak = (text) => {
    setCount(text);
    setTimeout(() => speakUt(text, setIsLoad, setSpeaking, setError), 500);
    resetTranscript();
  }

  useEffect(() => {
    if (error) {
      setCount(1);
      playError();
      setError(false);
    }
  }, [error])

  const playError = () => {
    audioRefErr.current.play().catch(error => {
      console.error('Error playing audio:', error);
    });
  };

  // const playLocal = () => {
  //   audioRefLocal.current.play()
  // };

  const playLocal = async (audio) => {
    await new Promise((res) => {
      setTimeout(() => {
        audio.play();
        audio.onended = res
      }, 100);
    });
  };

  useEffect(() => {
    aleshaAnswers()
  }, [transcript, audioRefLocal]);

  const aleshaAnswers = async () => {
    if (transcript == '') {return};
    if (transcript.toLocaleLowerCase().includes('лёш')) {
    
      if (transcript.length > 40) {
        resetTranscript();
      }

      if (checkWord(transcript, 'сколько') && checkWord(transcript, 'городов')) {
        const question = 'Я знаю 120 городов России';
        if (question == count) return;
        handleSpeak(question);
      } 
      if (checkWord(transcript, 'сколько') && (checkWord(transcript, ' довузов') || checkWord(transcript, 'до вузов'))) {
        const question = 'Я знаю 105 довузов России';
        if (question == count) return;
        handleSpeak(question);
      } 
      if (checkWord(transcript, 'сколько') && checkWord(transcript, ' вузов ')) {
        const question = 'Я знаю 89 вузов России';
        if (question == count) return;
        handleSpeak(question);
      }
      if (checkWord(transcript, 'кто ты') || checkWord(transcript, 'расскажи о себе')) {
        const question = 'Я голосовой ассистент Алёша, разработанный компанией nintegra';
        if (question == count) return;
        handleSpeak(question);
      } 
      if (checkWord(transcript, 'открой') && checkWord(transcript, 'карту')) {
        setIsMap(true);
        resetTranscript();
      } 
      if (checkWord(transcript, 'закрой') && checkWord(transcript, 'карту')) {
        setIsMap(false);
        resetTranscript();
      }
      if (checkWord(transcript, 'открой') && checkWord(transcript, 'карточку') && checkWord(transcript, 'мед') && checkWord(transcript, 'академии')) {
        setIsMap(true);
        setIsPopup(5);
        resetTranscript();
      }
      if (checkWord(transcript, 'закрой') && checkWord(transcript, 'карточку')) {
        setIsMap(true);
        setIsPopup(null);
        resetTranscript();
      }
      if (checkWord(transcript, 'назад') && isPopup) {
        setIsPopup(null);
        resetTranscript();
      } 
      if (checkWord(transcript, 'назад') && !isPopup) {
        setIsPopup(null);
        setIsMap(false);
        resetTranscript();
      }
      if (checkWord(transcript, 'назад') && !isPopup && !isMap) {
        setLocal(main_page);
        // setSpeaking(true);
        setSpeaking(true);
        console.log(audioRefLocal, 'audioRefLocal')
        await playLocal(
           audioRefLocal.current
        );
        setSpeaking(false);
      } 
    } else if (transcript && transcript.length>10 && transcript.toLocaleLowerCase().includes('лёш') != true) {
      resetTranscript();
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <audio src={sorry} ref={audioRefErr}></audio>
        <audio src={local ? local : null} ref={audioRefLocal}></audio>
        {/* <button className={styles.button} onClick={() => SpeechRecognition.startListening({ continuous: true })}>Старт</button> */}
        {/* <textarea value={transcript} disabled></textarea> */}
        {/* <div className={styles.buttons}>
          <button className={styles.button} onClick={() => SpeechRecognition.startListening({ continuous: true })}>Старт</button>
          {isLoad ? <div className={styles.loader}><Loader size={60} color="#007aff"/></div> : <></>}
          <button className={styles.button} onClick={SpeechRecognition.stopListening}>Стоп</button>
        </div> */}
      </div>
    </div>
  );
};

export default Speech;